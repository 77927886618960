import type { TagColors } from '@venncity/venn-ds';
import { Tag, Tooltip } from '@venncity/venn-ds';
import React, { memo, useEffect, useRef, useState, useCallback } from 'react';

interface TagCellProps {
  tags: { text: string; color: TagColors; icon?: React.ReactNode }[];
}

export const TagCell = memo(({ tags }: TagCellProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureContainerRef = useRef<HTMLDivElement>(null);

  const [visibleTags, setVisibleTags] = useState(tags);
  const [remainingCount, setRemainingCount] = useState(0);

  const calculateVisibleTags = useCallback(() => {
    const container = containerRef.current;
    const measureContainer = measureContainerRef.current;
    if (!container || !measureContainer) return;

    const containerWidth = container.offsetWidth;

    let totalWidth = 0;
    const remainingTagWidth = 40; // estimate for the '+X' tag
    let visibleCount = 0;

    for (let i = 0; i < tags.length; i++) {
      const tagWidth = measureContainer.children[i].getBoundingClientRect().width;
      if (totalWidth + tagWidth + (i < tags.length - 1 ? remainingTagWidth : 0) > containerWidth) {
        break;
      }
      totalWidth += tagWidth;
      visibleCount++;
    }

    setVisibleTags(tags.slice(0, visibleCount));
    setRemainingCount(tags.length - visibleCount);
  }, [tags]);

  useEffect(() => {
    calculateVisibleTags();

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleTags();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    window.addEventListener('resize', calculateVisibleTags);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', calculateVisibleTags);
    };
  }, [calculateVisibleTags]);

  const omittedTags = tags.slice(visibleTags.length);

  return (
    <>
      <div
        role="cell"
        className="flex h-full items-center"
        data-testid="cell-tags"
        ref={containerRef}>
        {visibleTags.map((tag) => (
          <Tag color={tag.color} key={tag.text} icon={tag.icon}>
            {tag.text}
          </Tag>
        ))}
        {remainingCount > 0 && (
          <Tooltip title={omittedTags.map((tag) => tag.text).join(', ')}>
            <Tag color="default" key="remaining">
              +{remainingCount}
            </Tag>
          </Tooltip>
        )}
      </div>
      <div className="pointer-events-none invisible absolute flex" ref={measureContainerRef}>
        {tags.map((tag) => (
          <Tag color={tag.color} key={`measure-${tag.text}`} icon={tag.icon}>
            {tag.text}
          </Tag>
        ))}
      </div>
    </>
  );
});

TagCell.displayName = 'TagCell';
